import { useState } from 'react';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { AppWidgetProps } from '../types/common';

export function useCheckoutLoader({
  isCheckoutDataInitialized,
  isDirectNavigation = false,
}: Pick<AppWidgetProps, 'isCheckoutDataInitialized' | 'isDirectNavigation'>) {
  const [isCheckoutComponentLoaded, setIsCheckoutComponentLoaded] = useState(false);
  const { experiments } = useExperiments();
  const { isSSR } = useEnvironment();
  const isDoNotShowLoaderOnDirectNavigationEnabled = experiments.enabled(
    TPA_EXPERIMENTS.DO_NOT_SHOW_LOADER_ON_DIRECT_NAVIGATION,
  );

  let shouldShowLoader;

  if (isDoNotShowLoaderOnDirectNavigationEnabled) {
    shouldShowLoader = (!isCheckoutDataInitialized || !isCheckoutComponentLoaded) && !isSSR && !isDirectNavigation;
  } else {
    shouldShowLoader = (!isCheckoutDataInitialized || !isCheckoutComponentLoaded) && !isSSR;
  }

  return { shouldShowLoader, setIsCheckoutComponentLoaded };
}
